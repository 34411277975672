exports.components = {
  "component---src-pages-explore-js": () => import("./../../../src/pages/explore.js" /* webpackChunkName: "component---src-pages-explore-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-guides-js": () => import("./../../../src/pages/guides.js" /* webpackChunkName: "component---src-pages-guides-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-new-reccs-js": () => import("./../../../src/pages/new-reccs.js" /* webpackChunkName: "component---src-pages-new-reccs-js" */),
  "component---src-pages-selector-js": () => import("./../../../src/pages/selector.js" /* webpackChunkName: "component---src-pages-selector-js" */),
  "component---src-templates-album-index-js": () => import("./../../../src/templates/Album/index.js" /* webpackChunkName: "component---src-templates-album-index-js" */),
  "component---src-templates-artist-index-js": () => import("./../../../src/templates/Artist/index.js" /* webpackChunkName: "component---src-templates-artist-index-js" */),
  "component---src-templates-author-index-js": () => import("./../../../src/templates/Author/index.js" /* webpackChunkName: "component---src-templates-author-index-js" */),
  "component---src-templates-feature-index-js": () => import("./../../../src/templates/Feature/index.js" /* webpackChunkName: "component---src-templates-feature-index-js" */),
  "component---src-templates-guide-index-js": () => import("./../../../src/templates/Guide/index.js" /* webpackChunkName: "component---src-templates-guide-index-js" */),
  "component---src-templates-selector-index-js": () => import("./../../../src/templates/Selector/index.js" /* webpackChunkName: "component---src-templates-selector-index-js" */),
  "component---src-templates-site-page-index-js": () => import("./../../../src/templates/SitePage/index.js" /* webpackChunkName: "component---src-templates-site-page-index-js" */)
}

