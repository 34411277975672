import * as styled from 'styled-components';
import ElevonZeroG from 'assets/fonts/Elevon_W_ZeroG.woff';

const GlobalStyles = styled.createGlobalStyle`
  @font-face {
    font-family: 'Elevon';
    src: url(${ElevonZeroG}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
  }

  html {
    font-size: 10px;
  }

  html,
  body {
    height: 100%;
    margin: 0;
    padding: 0;
  }

  *,
  ::before,
  ::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  body {
    font-family: Montserrat, Helvetica, 'Open Sans', Sans-Serif;
    font-weight: 500;
    line-height: 1;
  }

  p {
    font-weight: lighter;
    line-height: 1.8;
    a {
      text-decoration: underline;
      text-decoration-color: #7454a1;
    }
  }

  a {
    text-decoration: none;
  }

  h1,
  h2 {
    font-family: 'Elevon';
    text-transform: lowercase;
    font-weight: lighter;
  }

  h1 {
    font-size: 5rem;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: 3rem;
    }
  }

  h3 {
    font-size: 2.8rem;
  }

  h5 {
    font-size: 2rem;
    margin: 0;
  }

  a {
    text-decoration: none;
    color: black;
  }

  a,
  a:hover {
    transition: all 0.2s ease-in-out;
  }

  blockquote {
    display: inline-block;
    margin: 1.5rem 0;
    font-size: 2rem;
    font-weight: bold;
    border-bottom: 2px solid #e7334d;
  }

  input,
  textarea,
  button,
  select,
  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .press-japanese {
    color: rgba(0, 0, 0, 0.6);
  }

  @media screen and (max-width: 650px) {
    .youtube {
      height: 200;
    }
  }

  button {
    font-size: 12px;
    color: #b1b1b1;
    letter-spacing: 1px;
    border: 1px solid #b1b1b1 !important;
    border-radius: 20px;
    background-color: transparent;
    display: inline-block;
    padding: 10px 14px;
    margin-right: 10px;
    line-height: 1.25;
    border: 0;
    text-align: center;
    cursor: pointer;
    a {
      color: white;
    }
    span {
      margin-left: 4px;
    }
    &:hover {
      color: white;
      background-color: rgba(0, 0, 0, 0.2);
      border-color: transparent !important;
      &.active {
        color: white;
      }
    }
    &.active {
      border-color: black !important;
      color: white;
      background-color: black !important;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

export default GlobalStyles;
