const Theme = {
  breakpoints: {
    desktop: '1800px',
    laptop: '1500px',
    tabletLan: '1100px',
    tabletPor: '820px',
    mobile: '480px',
  },
  colors: {
    black: 'rgba(15, 25, 34, 1)',
    blue: 'rgba(86, 178, 195, 1)',
    gray: 'rgba(15, 25, 34, 0.7)',
    orange: 'rgba(246, 95, 48, 1)',
    purple: 'rgba(182, 126, 191, 1)',
    sand: 'rgba(249, 244, 241, 1)',
    lightBlue: 'rgba(86, 179, 195, 0.2)',
    lightGray: 'rgba(15, 25, 34, 0.15)',
    lightOrange: 'rgba(255, 212, 194, 1)',
    lightPurple: 'rgba(182, 126, 191, 0.2)',
    gradientBlue: 'linear-gradient(270deg, #56B2C3 0%, #008FAA 100%)',
  },
};

export default Theme;
